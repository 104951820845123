export const findBrowserType = () => {
  var opr = opr || undefined;
  var safari = safari || undefined;
  
  const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isChrome =  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // Opera 8.0+
  if (isOpera) {
    return 'Opera';
  }
  // Firefox 1.0+
  else if (typeof InstallTrigger !== 'undefined') {
    return 'Firefox';
  }
  // Safari 3.0+ "[object HTMLElementConstructor]" 
  else if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))) {
    return 'Safari';
  }
  // Internet Explorer 6-11
  else if (isIE) {
    return 'Internet Explorer';
  }
  // Edge 20+
  else if (!isIE && !!window.StyleMedia) {
    return 'Edge';
  }
  // Chrome 1 - 71
  else if (isChrome) {
    return 'Chrome';
  }
  // Blink engine detection
  else if ((isChrome || isOpera) && !!window.CSS) {
    return 'Blink';
  } 
  else {
    return `Unknown: ${window.navigator.userAgent}`;
  }

}