import axios from 'axios';
import uuid from 'uuid';
import { apiDomain, handleError} from './apiUtils';

export const sendCode = async (AccountId, Email, portalCode, portalSsoCode = null, PortalSession = {}) => {
  const requestId = uuid();
  const defaultNonePortalCode = '000000';
  let url;

  try { 
    url = `${apiDomain()}/authentication/portal/codes/${portalCode || defaultNonePortalCode}${portalSsoCode ? `?portalSsoCode=${portalSsoCode}` : ``}`;
    
    return await axios({
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "method-client": "portal-signin",
        "method-request-id": requestId,
      },
      data: {
        AccountId,
        PortalSession,
        Email
      }
    });
  } 
  catch(error) {
    return handleError(requestId, error, url);
  }
}
